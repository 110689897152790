import React, { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function Countdown({ orderTimeStamp, deliveryTime, color="white", state}) {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);
  const stateRef = useRef(state); // Create a ref to track the current state
  const [foodReady, setFoodReady] = useState(false);
  const [timer, setTimer] = useState(null);

  // Update stateRef whenever the state prop changes
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  
  const getTimeRemaining = (e) => {
      const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 / 60 / 60) % 24);
      return {
          total, hours, minutes, seconds
      };
  }

  const startTimer = (e) => {
      let { total, hours, minutes, seconds } 
                  = getTimeRemaining(e);
      if (total >= 0) {

          // update the timer
          // check if less than 10 then we need to 
          // add '0' at the beginning of the variable
          setTimer(
              (hours > 9 ? hours : '0' + hours) + ':' +
              (minutes > 9 ? minutes : '0' + minutes) + ':'
              + (seconds > 9 ? seconds : '0' + seconds)
          )
      }
  }


  /*const clearTimer = (e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next    
    //setTimer('00:00:00');

    // If you try to remove this line the 
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000)
    Ref.current = id;
  }*/
  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
      const currentTimeStamp = Date.now();
      const deadline = parseInt(orderTimeStamp) + deliveryTime * 60 * 1000;

      console.log(currentTimeStamp);
      console.log(deadline);
      console.log(currentTimeStamp > deadline);
      console.log(stateRef.current); // Use stateRef.current to access the latest state

      if ((currentTimeStamp > deadline) && stateRef.current === "\"accepted\"") { // Notice the direct string comparison
        console.log("food is ready");
        setFoodReady(true);
        clearInterval(Ref.current);
      } else if (!foodReady) {
        console.log("food not ready");
      }
    }, 1000);
    Ref.current = id;
  };

  // Clear the interval on component unmount
  useEffect(() => {
    return () => {
      if (Ref.current) clearInterval(Ref.current);
    };
  }, []);


  const getDeadTime = () => {
      let timeAtOrder = parseInt(orderTimeStamp);
      let deadline = new Date(timeAtOrder);
      let deliveryTimeSecond = deliveryTime * 60;
      console.log("this is the deadline" + deadline);
      console.log("this is the timestamp for countdown: " + timeAtOrder);

      // This is where you need to adjust if 
      // you entend to add more time
      deadline.setSeconds(deadline.getSeconds() + deliveryTimeSecond);
      return deadline;
  }


  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [deliveryTime]);


  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
      clearTimer(getDeadTime());
  }



  // Assuming stateTimerUpdates is a global array defined outside of your component
  let stateTimerUpdates = 0;

  /*useEffect(() => {
    stateTimerUpdates += 1;

    // Log the updated global array to the console to see the history of changes
    console.log(stateTimerUpdates);
    console.log("STATE: ", state);
    console.log("TIMER: ", timer);
    console.log("delivery time: ", deliveryTime);
    console.log("ordertimestamp: ", orderTimeStamp);
    console.log(new Date());

    let millisecondsToAdd = deliveryTime * 60 * 1000;
    let deadline = parseInt(orderTimeStamp) + millisecondsToAdd;

    let currentTimeStamp = Date.now();

    console.log(currentTimeStamp);
    console.log(deadline);

    if( (currentTimeStamp > deadline) && state === "\"accepted\""){
      console.log("food is ready");
      setFoodReady(true);
    } else {
      console.log("food not ready");
      if(foodReady){
        setFoodReady(false);
      }
    }

    // This approach adds a new entry for every change, providing a detailed log of changes over time.
    // Including a timestamp or a change ID could help in distinguishing between entries and tracking when changes occurred.

  }, [state, timer]);*/



  
    return (
        <div className="App">
            {/*state === "\"pending\"" ? <h4 style={{color:color}}>Vent til restauranten har accepteret bestillingen</h4> : ( timer === "00:00:00" ? <h3 style={{color:color}}>Maden er klar</h3> : <h2 style={{color:color}} >{timer ? timer : "Vent til restauranten har accepteret bestillingen" } </h2> ) */}

            {/*state && timer ? <h2> izz set </h2> : <h1> NOO </h1>*/}

            { (state === "\"pending\"") ? 

                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress size={300} disableShrink sx={{
        // Override the animation duration
        "& .MuiCircularProgress-circle": {
          animationDuration: '10.5s', // Adjust this value to control the speed
        }
      }} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{color:color}} variant="h4" component="div" >
                      Personalet tjekker din bestilling, vent venligst.
                    </Typography>
                  </Box>
                </Box> 

            : null } 
            { (timer !== "00:00:00") && (state === "\"accepted\"") ? <h2 style={{color:color}} >{timer}</h2> : null } 
            { foodReady && (state === "\"accepted\"") ? <h3 style={{color:color}}>Maden er klar</h3> : null } 
        </div>
    )

}

export default Countdown;
