import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
//import { initializeApp } from "firebase/app";
//import { getDatabase } from "firebase/database";
import MainElements from "./Components/MainElements";
import FirebaseTest from "./Components/FirebaseTest";

/*const firebaseConfig = {
  databaseURL: "https://takeawaysystems-cloud-default-rtdb.europe-west1.firebasedatabase.app/"
};*/

function App() {

  /*useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
  }, []);*/


  return <>
        <MainElements/>
        {/*<FirebaseTest/>*/}
  </>;
}

export default App;
