import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  totalPrice: {
    fontSize: "2rem",
    fontWeight: "bold",
    //marginTop: theme.spacing(2),
  },
}));

export default function CheckoutFooter(props) {
  const classes = useStyles();
  const totalPrice = props.totalPrice;

  return (
    <BottomNavigation className={classes.root}>
      <Box className={classes.totalPriceContainer}>
        <Typography variant="h6" className={classes.totalPrice}>
          Total: <span style={{color:"#009de0"}} >{totalPrice} kr</span>
        </Typography>
      </Box>
    </BottomNavigation>
  );
}
