import React, {useEffect, useState} from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBx3wVu82quAesPuBZGR2-rp0b7z7OisP4",
  authDomain: "takeawaysystems-cloud.firebaseapp.com",
  databaseURL: "https://takeawaysystems-cloud-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "takeawaysystems-cloud",
  storageBucket: "takeawaysystems-cloud.appspot.com",
  messagingSenderId: "728810133541",
  appId: "1:728810133541:web:0840b05acc6ebb6f5a07cc",
  measurementId: "G-HREX5MWPVB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);


export default function FirebaseTest() {

  const [data, setData] = useState(null);


  useEffect(() => {
    const query = ref(database, "/Orders/0/-NMUekj_gyPibFAtupFR/deliveryTime");
    onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()){
        /*Object.values(data).map((order) => {
          setData((orders) => [...orders, order]);
        });*/
        setData(JSON.stringify(data));
      }
    });
  }, []);

  //const starCountRef = ref(database, 'Orders/0/NMUekj_gyPibFAtupFR/totalPrice');
  /*onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    setData(data);
  });*/

  return <h1>{data}</h1>;
}
