import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CountDown from './CountDown';


const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		width: '100%',
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	totalPrice: {
		fontSize: "2rem",
		fontWeight: "bold",
		//marginTop: theme.spacing(2),
	},
}));

export default function ScrollHeader(props) {

	const {timeStamp, deliveryTime, state} = props;

	const classes = useStyles();
	const [showHeader, setShowHeader] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	function handleScroll() {
		if (window.pageYOffset > 0) {
			setShowHeader(true);
		} else {
			setShowHeader(false);
		}
	}

	return (
		<AppBar className={classes.root} style={{ display: showHeader ? 'block' : 'none' }}>
			<Toolbar style={{justifyContent:"center"}}>
				<Box>
		            {timeStamp && <CountDown orderTimeStamp={timeStamp} deliveryTime={deliveryTime} state={state} color="black" />}
	        	</Box>
			</Toolbar>
		</AppBar>
	);
}